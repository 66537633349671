import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import appInfo from '../../../.application.json';

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}: ControllerParams) => {
  const { setProps, config } = controllerConfig;
  const { isMobile, isEditor } = flowAPI.environment;
  const spotifyUri = config.publicData.COMPONENT?.spotifyURI;

  return {
    async pageReady() {
      setProps({
        spotifyUri,
        appName: appInfo.appName,
        isEditor,
        mobile: isMobile,
      });
    },
    updateConfig($w: unknown, newConfig: any) {
      setProps({
        spotifyUri: newConfig.publicData.COMPONENT?.spotifyURI,
      });
    },
  };
};

export default createController;
